import React from "react";
import { MenuProvider } from "./src/context";

export const wrapRootElement = ({ element }) => <MenuProvider>{element}</MenuProvider>;

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (typeof window !== "undefined") {
    window.previousPath = prevLocation ? prevLocation.href : "";
  }
};
